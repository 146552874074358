<template>
  <div class="container">
    <h1>Legal Links</h1>
    <div class="button-container">
      <PassportButton label="Terms & Conditions" @click="viewPolicy('terms')" class="button" />
      <PassportButton label="Privacy Policy" @click="viewPolicy('privacy')" class="button" />
      <PassportButton label="Cookie Policy" @click="viewPolicy('cookie')" class="button" />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';

export default {
  name: 'Login',
  components: {
    PassportButton,
  },
  data() {
    return {
      showNav: false,
    };
  },
  mounted() {},
  methods: {
    viewPolicy(policy) {
      if (policy === 'privacy') {
        window.open('https://www.iubenda.com/privacy-policy/92199337', '_blank');
      } else if (policy === 'terms') {
        window.open('https://www.iubenda.com/terms-and-conditions/92199337', '_blank');
      } else if (policy === 'cookie') {
        window.open('https://www.iubenda.com/privacy-policy/92199337/cookie-policy', '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-top: 40px;
}
.paragraph {
  padding: 25px;
  font-size: 18px;
  text-align: left;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 53px;
  margin-top: 1em;
  font-family: $head-font-stack;
  font-size: 16px;
  text-transform: uppercase;
  background-color: $primary-white;
  @include bp-sm-phone-landscape {
    width: 300px;
  }
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
</style>
